/* iCheck plugin Flat skin, grey
----------------------------------- */
.icheckbox_flat-grey,
.iradio_flat-grey {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(grey.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-grey {
    background-position: 0 0;
}
    .icheckbox_flat-grey.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-grey.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-grey.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-grey {
    background-position: -88px 0;
}
    .iradio_flat-grey.checked {
        background-position: -110px 0;
    }
    .iradio_flat-grey.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-grey.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_flat-grey,
    .iradio_flat-grey {
        background-image: url(grey@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}