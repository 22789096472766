//custom mixin
@function rem-calc($size) { $remSize: $size / 16; @return #{$remSize}rem; }

// Bootstrap 3.3.5 mixin required by my mixin
// bootstrap-sass/assets/stylesheets/bootstrap/mixins/_buttons.scss
@mixin button-variant($color, $background, $border) {
	color: $color;
	background-color: $background;
	border-color: $border;
	&:focus, &.focus {
	  color: $color;
	  background-color: darken($background, 10%);
	  border-color: darken($border, 25%);
	}
	&:hover {
	  color: $color;
	  background-color: darken($background, 10%);
	  border-color: darken($border, 12%);
	}
	&:active, &.active, .open > &.dropdown-toggle {
	  color: $color;
	  background-color: darken($background, 10%);
	  border-color: darken($border, 12%);
  
	  &:hover, &:focus, &.focus {
		color: $color;
		background-color: darken($background, 17%);
		border-color: darken($border, 25%);
	  }
	}
	&:active, &.active, .open > &.dropdown-toggle {
	  background-image: none;
	}
	&.disabled, &[disabled], fieldset[disabled] & {
	  &, &:hover, &:focus, &.focus, &:active, &.active {
		background-color: $background;
		border-color: $border;
	  }
	}
	.badge {
	  color: $background;
	  background-color: $color;
	}
  }
  
  // Mixin to create a bootstrap button with custom colors
  @mixin bootstrap-button($background) {
	$color: #fff;
	$border: 5%;
	@if (lightness($background) >= lightness(#aaa)) {
	  $color: #333;
	  $border: .2 * lightness($background);
	}
	@include button-variant($color, $background, darken($background, $border));
  }
  
  // Social buttons
  .btn-facebook    { @include bootstrap-button(#3b5999); }
  .btn-twitter     { @include bootstrap-button(#55acee); }
  .btn-google-plus { @include bootstrap-button(#df4a32); }
  .btn-pinterest   { @include bootstrap-button(#cc2127); }
  
  // Gray buttons
  .btn-0 { @include bootstrap-button(#000); }
  .btn-1 { @include bootstrap-button(#111); }
  .btn-2 { @include bootstrap-button(#222); }
  .btn-3 { @include bootstrap-button(#333); }
  .btn-4 { @include bootstrap-button(#444); }
  .btn-5 { @include bootstrap-button(#555); }
  .btn-6 { @include bootstrap-button(#666); }
  .btn-7 { @include bootstrap-button(#777); }
  .btn-8 { @include bootstrap-button(#888); }
  .btn-9 { @include bootstrap-button(#999); }
  .btn-a { @include bootstrap-button(#aaa); }
  .btn-b { @include bootstrap-button(#bbb); }
  .btn-c { @include bootstrap-button(#ccc); }
  .btn-d { @include bootstrap-button(#ddd); }
  .btn-e { @include bootstrap-button(#eee); }
  .btn-f { @include bootstrap-button(#fff); }
  
  // Random colored buttons
  @for $i from 1 through 10 {
	.btn-random-#{$i} {
	  $color: rgb(random(255), random(255), random(255));
	  @include bootstrap-button($color);
	  &::after {
		content: "#{$color}";
	  }
	}
  }
  
  .btn-helloworld { @include bootstrap-button(#3566e7); }
  
/*
####################################################
M E D I A  Q U E R I E S
####################################################
*/

@media print {
	.prescription-label{
		height: 240.4px;
		width: 400px;
		p{
			margin: 0;
		}
		.verb{
			float: right;
			width: 69%;
		}
		.black-stocker{
			// background: url('../img/black-stocker.png');
			height: 3rem;
			background-position: left;
			background-size: contain;
			background-repeat: no-repeat;
			-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
			color-adjust: exact !important;                 /*Firefox*/
			width: 31%;
			float: left;
			img{
				height: 3rem;
			}
		}
		span{
			font-weight: 900;
		}
		header{
			// margin-bottom: 1rem;
			div{
				float: left;
				width: 50%;
			}
		}
		h1{
			font-size: 1em;
			margin-block-start: 0.83em;
			margin-block-end: 0.83em;
			margin: .2rem 0;
			font-weight: 700;
		}
		p{
			margin: .1rem 0;
			font-size: rem-calc(9);
		}
		table{
			width: 100%;
			tr{
				vertical-align: top;
			}
			th{
				font-weight: 900;
			}
			th, td{
				border: none;
				padding: 0 rem-calc(10) 0 0;
				font-size: rem-calc(8);

			}
		}
	}
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
 
/* 
Extra small devices (portrait phones, less than rem-calc(576)) 
No media query since this is the default in Bootstrap because it is "mobile first"
*/

.action-none{
	opacity: 0;
	position: absolute;
	left: -20000px;
}
.login-pag{
	background-image:url('../img/pexels-photo-735973.jpeg');
	background-size: cover;
	background-position: center;
	.login-box{
		background: rgba($red, 0.8);
		width: 100%;
		max-width: rem-calc(500);
		padding:rem-calc(20);
		.login-logo{
			background-image: url('../img/stocker-supply-2-lines-white-2.png');
			background-size: cover;
			background-position: center;

			height: rem-calc(200);
		}
	}
}

.pace-active{
	.pace-activity{
		top:0;
		right:0;
		bottom:0;
		left:0;
		margin: auto;
		border:0;
		width: 100px;
		height: 100px;
		opacity: .5;
		&:before{
			font-size: 100px;
		}
		@extend .fas; 
		@extend .fa-sync; 
		@extend .fa-spin;
	}
}

.sidebar{
	.tree{
		li{
			a{
				.fa{
					text-align: center;
					width: rem-calc(25);
				}
			}
		}
	} 
	.user-panel > .info{
		position: initial;
	}
}
.view-wrap{
	margin-top: 1rem;
}
.modal{
	width: 100%;
	.modal-dialog{
		max-width: 800px;
	}
	.prescription-label{
		height: 240.4px;
		width: 400px;
		p{
			margin: 0;
		}
		.verb{
			float: right;
			width: 69%;
		}
		.black-stocker{
			// background: url('../img/black-stocker.png');
			height: 3rem;
			background-position: left;
			background-size: contain;
			background-repeat: no-repeat;
			-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
			color-adjust: exact !important;                 /*Firefox*/
			width: 31%;
			float: left;
			img{
				height: 3rem;
			}
		}
		span{
			font-weight: 900;
		}
		header{
			// margin-bottom: 1rem;
			div{
				float: left;
				width: 50%;
			}
		}
		h1{
			font-size: 1em;
			margin-block-start: 0.83em;
			margin-block-end: 0.83em;
			margin: .2rem 0;
			font-weight: 700;
		}
		p{
			margin: .1rem 0;
			font-size: rem-calc(9);
		}
		table{
			width: 100%;
			tr{
				vertical-align: top;
			}
			th{
				font-weight: 900;
			}
			th, td{
				border: none;
				padding: 0 rem-calc(10) 0 0;
				font-size: rem-calc(8);

			}
		}
	}
}
#success{
	position: fixed;
	background: rgba($green, .75);
	right:0;
	left: 0;
	z-index: -10;
	margin: auto;
	width: 300px;
	height: 200px;
	opacity: 0;
	transition: .5s;
	font-size: 40px;
	padding: 20px;
	border-top-left-radius: 100%;
	border-top-right-radius: 100%;
	text-align: center;
	border: 2px solid black;
	i{
		&:before{

			font-size: 100px;
		}
	}
	&.show{
		opacity: 1;
		z-index: 10000;
	}
}
.navbar-custom-menu{
	.nav{
		flex-direction: row;

		li{
			a{
				padding: rem-calc(10) rem-calc(20);
				i{
					text-align: center;
					width: rem-calc(25);
				}
			}
		}
	}
}
.main-header .logo .logo-lg{
	background-image: url('../img/stocker-supply-2-lines-white-2.png');
	background-position: left;
	background-size: contain;
	width: rem-calc(200);
	height: rem-calc(50);
	background-repeat: no-repeat;
}
.logout{
	a{
		color: $white;
		background: rgba(0,0,0,0.1);
		&:hover{
			color: #f6f6f6;
			background-color: #d73925;
		}
	}
}
.content{

		

	
	.nav-tabs-custom{
		box-shadow: rem-calc(1) rem-calc(1) rem-calc(6) rem-calc(1);
		overflow:hidden;
		.nav-tabs{
			border-color: $black;
			border-radius: 0;
			@include gradient-y( #dd4b39, $white, 0%, 4%);
			li{
				padding: rem-calc(20);
				a{

					box-shadow: rem-calc(0) rem-calc(0) rem-calc(0) rem-calc(0) $white;
					padding: rem-calc(20);
					transition: .5s;
					background-color: $gray-300;
					border-radius: rem-calc(2);
					border-top: rem-calc(1) solid $gray-600;
					border-left: rem-calc(1) solid $gray-600;
					border-right: rem-calc(1) solid $gray-600;
					i{
						&.fa{
							text-align: center;
							width: rem-calc(25);
						}
						&:before{
							position: relative;
							bottom: rem-calc(0);
							transition: .5s;   
						}
					}
					&:hover,&:active,&:focus, &.active{
						border-top: rem-calc(1) solid $black;
						border-left: rem-calc(1) solid $black;
						border-right: rem-calc(1) solid $black;
						background-color: $white;
						box-shadow: rem-calc(0) rem-calc(2) $white;
						text-decoration: none;
						position: relative;
						z-index: 200;

						i{
							&:before{
								bottom: rem-calc(2);
							}
						}
					}
				}
			}
		}
		.tab-content{
			box-shadow:0 rem-calc(0) 4.375rem rem-calc(2);
			position: relative;
			.tab-pane{
				overflow-y: hidden;
				overflow-x: scroll;
			}
			.delete-mode-wrap{
				position: absolute;
				width: auto;
				text-align: left;
				right: 0;
				bottom: rem-calc(33);
				div{
					display: inline-block;
				}

			}
			.buttons{
				padding: rem-calc(5) 0;
				display: flow-root;
				div.dt-buttons{
					float: right;
				}
			}
		}
	}
	.stocker_form{
		display: flex;
		flex-flow: wrap;
		.progress{
			padding: 0;
			height: rem-calc(10);
			border-radius: 0;
			border-bottom-left-radius: 100%;
			border-bottom-right-radius: 100%;
		}
		.selectpicker.custom-select{
			background: none;
		}
		::-webkit-input-placeholder {
			color: $gray-300;
			opacity: 1 !important; /* for older chrome versions. may no longer apply. */
		}
		
		:-moz-placeholder { /* Firefox 18- */
			color: $gray-300;
			opacity: 1 !important;
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			color: $gray-300;
			opacity: 1 !important;
		}
		
		:-ms-input-placeholder {  
		   color: $gray-300;
		}
	}
	.btn-group{

		.dropdown-item.active{
			background: $danger;
		}
	}

	.stocker_tables{
		thead{
			tr{
				height:80px;
				&:first-child{
					background-color: $gray-400;
				}
			}
		}
		tr{
			&:nth-child(2n - 1){
				background-color: $gray-100;
				&:hover{
					background-color: darken($gray-100, 15%);
				}
			}
			&:nth-child(2n){
				background-color: $gray-300;
				&:hover{
					background-color: darken($gray-300, 15%);
				}
			}
			td{
				p{
					margin: 0;
				}
				a{
					text-decoration: none;
					color: $danger;

				}
			}
		}
	}
	.dataTables_paginate{
		.paginate_button{
			padding: rem-calc(5) rem-calc(20);
			margin: rem-calc(10);
			$background: $danger;
			transition: 0.3s;
			@include bootstrap-button($background);
			&.disabled{
				display: none;
			}
		}
	}
}

 
/* Small devices (landscape phones, rem-calc(576) and up) */
@media (min-width: rem-calc(576)) {  
 
}
 
/* Medium devices (tablets, rem-calc(768) and up) The navbar toggle appears at this breakpoint */
@media (min-width: rem-calc(768)) {  
 
}
 
/* Large devices (desktops, rem-calc(992) and up) */
@media (min-width: rem-calc(992)) { 
 
}
 
/* Extra large devices (large desktops, rem-calc(1200) and up) */
@media (min-width: rem-calc(1400)) {  
	.content{
		.nav-tabs-custom{
			.tab-content{
				.tab-pane{
					overflow-y: hidden;
					overflow-x: hidden;
				}
			}
		}
	} 
}
 
 
 
/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Custom media queries
*/
 
/* Set width to make card deck cards 100% width */
@media (max-width: rem-calc(950)) { 
 
}

